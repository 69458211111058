import React, { Component } from "react";

class Header extends Component {
  render() {
    if (/Mac/i.test(navigator.platform)) {
      return (
        <header>
          <h1 id="app-header">Book Note Taker</h1>
          <ul className={this.props.validStatus ? "valid" : "invalid"}>
            <li> <button href="#" onClick={this.props.addHighlightHandler}>
              &#x2303;+&#x2b06;+&#8997;+H: Insert a Highlight</button>
            </li>
            <li><button href="#" onClick={this.props.addNoteHandler}>
              &#x2303;+&#x2b06;+&#8997;+N: Insert a Note</button>
            </li>
            <li><button onClick={this.props.resetHandler} className="danger">&#x2303;+&#x2b06;+Q: Nuke this document</button></li>
          </ul>
        </header>
      );
    } else {
      return (
        <header>
          <h1 id="app-header">Book Note Taker</h1>
          <ul className={this.props.validStatus ? "valid" : "invalid"}>
            <li>&#x2303;+&#x2b06;+Alt+H: Insert a Highlight</li>
            <li>&#x2303;+&#x2b06;+Alt+N: Insert a Note</li>
            <li className="danger">&#x2303;+&#x2b06;+Q: Nuke this document</li>
          </ul>
        </header>
      );
    }
  }
}

export default Header;
