import React, { Component } from "react";
import "./App.css";
import Header from "./Header";
import JsonField from "./JsonField";

const INITIAL_JSON = {
  title: "",
  author: "",
  highlightCount: 0,
  noteCount: 0,
  annotations: []
};

const highlightJSONTemplate = {
  type: "qZHighlight",
  highlight: ""
};

const flagStr = '"highlight":';

const blankNoteJSON = {
  type: "qZNote",
  highlight: "",
  annotation: ""
};

const STRINGIFY_VALUE = 2;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentString: "{}",
      contentIsValidJSON: true,
      caretPos: 0
    };
  }

  /* METHODS */

  setContentStringAndValidate(s, opts = {}) {
    let validStatus = true;
    let defaultOpts = { caretPos: 0 };

    try {
      JSON.parse(s);
    } catch (er) {
      validStatus = false;
    }

    const config = Object.assign(
      {
        contentString: s,
        contentIsValidJSON: validStatus
      },
      defaultOpts,
      opts
    );

    this.setState(config);
  }

  componentDidMount() {
    const retrievedStr = localStorage.getItem("bookNote");
    let savedState = retrievedStr
      ? retrievedStr
      : JSON.stringify(INITIAL_JSON, null, STRINGIFY_VALUE);

    window.setInterval(this.backupToLocalStorage, 1000);
    this.setContentStringAndValidate(savedState);
  }

  backupToLocalStorage = () => {
    localStorage.setItem("bookNote", this.state.contentString);
  };

  _insertInJsonStringAt(s, pos, inj) {
    const left = s.slice(0, pos);
    const right = s.slice(pos).trim();
    const newStr =
      left +
      (left[left.length - 1].match(/[,[]/) ? "" : ",") +
      inj +
      (right[0] === "{" ? "," : "") +
      right;
    return newStr;
  }

  /* HANDLERS */

  jsonFieldClickHandler = e => {
    this.setState({ caretPos: e.target.selectionStart });
  };

  handleChange = e => {
    const { value } = e.target;
    const caretPos = e.target.selectionStart;

    this.setContentStringAndValidate(value, { caretPos });
  };

  jsonFieldKeyboardHandler = e => {
    const dispatchMap = new Map([
      [78, this.addNote],
      [72, this.addHighlight],
      [91, this.resetBooknoteInLocalStorage]
    ]);

    const hotkeyCallback = () => {
      if (
        e.ctrlKey &&
        e.shiftKey &&
        e.altKey
      ) {
        (
          dispatchMap.get(e.keyCode) ??
          function(x){console.log('noop ' + e.key + e.keyCode)}
        )(e);
      }
    };

    /* React re-uses the synthetic event in 'e'. Ensure this sticks
     * around before the hotkeyCallbackc uses it
     */
    e.persist();

    this.setState({ caretPos: e.target.selectionStart }, hotkeyCallback);
  };

  resetBooknoteInLocalStorage = e => {
    localStorage.removeItem("bookNote");
    localStorage.setItem("bookNote", JSON.stringify(INITIAL_JSON, null, 2));
    window.location.reload();
  }

  addHighlight = e => {
    if (!this.state.contentIsValidJSON) return;

    const $target = e.target;
    const savedCaretPos = this.state.caretPos; // updated by handleChange

    this.setState(
      (state, props) => {
        let newObj;
        const newStr = this._insertInJsonStringAt(
          this.state.contentString,
          savedCaretPos,
          JSON.stringify(highlightJSONTemplate, null, STRINGIFY_VALUE)
        );

        try {
          newObj = JSON.parse(newStr);
        } catch (e) {
          console.error(
            `INTERNAL ERROR: Newly inserted highlight broke valid contentString: ${
              e.message
            } in ${newStr}`
          );
          throw e;
        }

        if (newObj.annotations) {
          const newObjIdx = newObj.annotations.findIndex(
            o => o.type === "qZHighlight"
          );

          const prevObjIdx = newObjIdx - 1;
          const location =
            prevObjIdx === -1 ? 0 : newObj.annotations[prevObjIdx].location;

          newObj.annotations[newObjIdx].type = "Highlight";
          newObj.annotations[newObjIdx].location =
            typeof location == "number" ? location : "UNKNOWN";
        }

        if (newObj.hasOwnProperty("highlightCount")) {
          newObj.highlightCount++;
        }

        return {
          contentString: JSON.stringify(newObj, null, STRINGIFY_VALUE),
          caretPos: savedCaretPos
        };
      },
      () => this._prepForHighlightEdit($target, savedCaretPos)
    );
  };

  addNote = e => {
    if (!this.state.contentIsValidJSON) return;

    const $target = e.target;
    const savedCaretPos = this.state.caretPos; // updated by handleChange
    let editPos;

    this.setState(
      (state, props) => {
        let newObj;
        const newStr = this._insertInJsonStringAt(
          this.state.contentString,
          savedCaretPos,
          JSON.stringify(blankNoteJSON, null, STRINGIFY_VALUE)
        );

        try {
          newObj = JSON.parse(newStr);
        } catch (e) {
          console.error(
            `INTERNAL ERROR: Newly inserted highlight broke valid contentString: ${
              e.message
            } in ${newStr}`
          );
          throw e;
        }

        if (newObj.annotations) {
          const newObjIdx = newObj.annotations.findIndex(
            o => o.type === "qZNote"
          );

          const prevObjIdx = newObjIdx - 1;
          const location =
            prevObjIdx === -1 ? 0 : newObj.annotations[prevObjIdx].location;

          newObj.annotations[newObjIdx].type = "Note";
          newObj.annotations[newObjIdx].location =
            typeof location == "number" ? location : "UNKNOWN";
        }

        if (newObj.hasOwnProperty("noteCount")) {
          newObj.noteCount++;
        }

        editPos = this._calcNoteEditPos($target, savedCaretPos);

        return {
          contentString: JSON.stringify(newObj, null, STRINGIFY_VALUE),
          caretPos: editPos
        };
      },
      () => {
        setTimeout(() => {
          $target.selectionStart = editPos;
          $target.selectionEnd = editPos;
          $target.blur();
          $target.focus();
        }, 0);
      }
    );
  };

  _prepForHighlightEdit = (target, savedCaretPos) => {
    const editPos =
      savedCaretPos +
      JSON.stringify(highlightJSONTemplate, null, STRINGIFY_VALUE).indexOf(
        flagStr
      ) + // Position match
      flagStr.length + // length of unique-ish flag match
      2 * STRINGIFY_VALUE * 3 + // indent level of STRINGIFY_VALUE times 3 steps in, twice
      1; // a bump to move inside the value of the key
    target.selectionStart = editPos;
    target.selectionEnd = editPos;
  };

  _calcNoteEditPos = (target, savedCaretPos) => {
    const editPos =
      savedCaretPos +
      JSON.stringify(highlightJSONTemplate, null, STRINGIFY_VALUE).indexOf(
        flagStr
      ) + // Position match
      flagStr.length + // length of unique-ish flag match
      1 * STRINGIFY_VALUE * 3 + // indent level of STRINGIFY_VALUE times 3 steps in, by number of prev keys
      2; // a bump to move inside the value of the key

    return editPos;
  };

  render() {
    return (
      <div id="book-note-taker-app">
        <Header
          validStatus={this.state.contentIsValidJSON}
          addNoteHandler={this.addNote}
          addHighlightHandler={this.addHighlight}
          resetHandler={this.resetBooknoteInLocalStorage}
        />
        <JsonField
          src={this.state.contentString}
          validStatus={this.state.contentIsValidJSON}
          onChange={this.handleChange}
          caretPos={this.state.caretPos}
          onClick={this.jsonFieldClickHandler}
          onKeyDown={this.jsonFieldKeyboardHandler}
        />
      </div>
    );
  }
}

export default App;
