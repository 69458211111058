import React, { Component } from "react";

class JsonField extends Component {
  render() {
    return (
      <div
        id="json-zone"
        onKeyDown={this.props.onKeyDown}
        onClick={this.props.onClick}
      >
        <textarea
          id="json-display"
          className={this.props.validStatus ? "valid" : "invalid"}
          onChange={this.props.onChange}
          value={this.props.src}
          rows="20"
        />
      </div>
    );
  }
}

export default JsonField;
